<template>
  <div id="p2p-market-history-list">
    <table-action-row
      :filterList="listQuery"
      canFilterDate
      @onFilterChange="
        (value) => {
          listQuery = value;
          handleFilter();
        }
      "
    />

    <el-table 
      v-loading="isLoading" class="newsily-bo-table row-clickable" :data="list"
      @row-click="handleRowClick"
    >
      <el-table-column prop="transactionRef" :label="$t('TRANSACTION_REFERENCE_ID')" />
      <el-table-column :label="$t('TRANSACTION_DATE')" :width="isMobile ? '150' : null">
        <template slot-scope="{row}">
          {{ parseDate(row.initiatedDate) }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('TYPE')"
        width="200"
      >
        <template slot-scope="{row}">
          {{ $t(row.buyer.id === user.id ? 'BUY' : 'SELL') }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('MEMBER_NAME')">
        <template slot-scope="{row}">
          {{ getFullName(row.buyer.id === user.id ? row.seller : row.buyer) }}
        </template>
      </el-table-column>
      <el-table-column prop="coin" :label="$t('NO_OF_COIN')" />
      <el-table-column :label="$t('PRICE_PER_COIN')">
        <template slot-scope="{row}">
          {{ `${row.post.currency.code.toUpperCase()} ${row.post.price}` }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('STATUS')"
        width="100"
      >
        <template slot-scope="{ row }">
          <span :class="getStatusClass(row.transactionStatus === 'INITIATED' ? 'PENDING' : row.transactionStatus)">
            {{ getP2PStatusLabel(row) }}
          </span>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import TableActionRow from "@/components/table/table-action-row.vue";
import Pagination from "@/components/table/pagination.vue";
import { generalMixin } from "@/utils/general-mixin.js";
import { PAGE_SIZES } from "@/constants";
import { mapGetters } from "vuex";
import { getTransactionList } from "@/api/p2p-market";

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  orderCol: "initiated_date",
  desc: true,
  dateFrom: null,
  dateTo: null,
  showHistoryList: true,
};

export default {
  name: "P2PMarketHistoryList",
  mixins: [generalMixin],
  components: { TableActionRow, Pagination },
  data() {
    return {
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      total: 0,
      list: [],
      moment: moment,
    }
  },
  computed: {
    ...mapGetters(["user"]),
    searchKeyword: {
      get() {
        return this.listQuery.search;
      },
      set(value) {
        this.listQuery.search = value;
      },
    },
  },
  methods: {
    handleReset() {
      this.listQuery = cloneDeep(filterQuery);
      this.handleFilter();
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page;
      this.listQuery.limit = val.limit;
      this.getList();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch == false) {
        this.searchKeyword = "";
        this.getList();
      }
    },
    handleRowClick(row) {
      this.goTo(`/bo/p2p-market/transaction/${row.transactionRef}`)
    },
    getP2PStatusLabel(row) {
      let statusCode = row.transactionStatus
      if (statusCode === "INITIATED") statusCode = "PENDING"
      return this.$t(statusCode)
    },
    getList() {
      this.isLoading = true;
      getTransactionList(this.listQuery)
        .then((res) => {
          if (res.status != "ok") return;
          console.log("Got: ", res);
          this.list = cloneDeep(res.result.list)
          this.total = res.result.total;
          
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.handleReset();
  },
}
</script>


<style lang="scss">
  @import "@/assets/style/mixin/table.scss";
  
  #p2p-market-history-list {
    @include newsily-bo-table;
  }
  </style>