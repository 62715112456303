<template>
  <el-dialog
    :fullscreen="isMobile"
    :visible.sync="isVisible"
    custom-class="delete-post-popup"
    width="30%"
    center
    destroy-on-close
    append-to-body
  >
    <div slot="title" class="text-left">
      <b>{{
        type == "BUY" ? $t("PLS_DELETE_BUY_REQ") : $t("PLS_DELETE_SELL_REQ")
      }}</b>
    </div>
    <div :class="{ mobile: isMobile }">
      {{
        type == "BUY" ? $t("BUY_REQ_WARNING") : $t("SELL_REQ_WARNING")
      }}
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="isVisible = false" class="w-25 btn-cancel">{{
        $t("CANCEL")
      }}</el-button>
      <el-button v-if="url" class="w-auto button green text-bold" @click="goTo(url)">{{
        type == "BUY" ? $t("EDIT_CURRENT_BUY_REQ") : $t("EDIT_CURRENT_SELL_REQ")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js";

export default {
  mixins: [generalMixin],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    type: { type: String, required: true },
    url: { type: String },
    
  },
  data() {
    return {};
  },
  computed: {
    isVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.delete-post-popup {
  border-radius: 5px;
  .el-dialog__header {
    border-bottom: 1px solid #c7c7c77d;
  }
  .el-dialog__footer {
    border-top: 1px solid #c7c7c77d;
  }
  .el-dialog__body {
    padding: 3rem 1rem;
    word-break: break-word;
    color: #767396;
  }
  .btn-cancel {
    color: #a8a8a8;
  }
}
</style>
