<template>
  <div id="p2p-market-trade-list">
    
    <table-action-row
      :filterList="listQuery"
      @onFilterChange="
        (value) => {
          listQuery = value;
          handleFilter();
        }
      "
    />

    <h3>{{ $t("TRADE") }}</h3>

    <div class="list-wrapper">
      <div v-if="list.length === 0" class="trade-container empty">
        {{ $t("NO_ACTIVE_TRADE_YET") }}
      </div>
      <div 
        v-else
        v-for="el in list"
        :key="el.id"
        class="trade-container slide-left pointer"
        :class="{
          primary: !isUserSelling(el),
          green: isUserSelling(el),
        }"
        @click="handleRowClick(el)"
      >
        <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle">
          <el-col :span="24">
            <el-row class="w-100 header-wrapper" :type="isMobile ? '' : 'flex'" justify="start" align="middle" :gutter="isMobile ? 0 : 40">
              <el-col :span="isMobile ? 24 : 6">
                <el-tag 
                  class="header-tag"
                  :class="{
                    primary: !isUserSelling(el),
                    green: isUserSelling(el),
                  }"
                >
                  <span v-html="getHeader(el)" />
                </el-tag>
              </el-col>
              <el-col :span="isMobile ? 24 : 10">
                <el-row
                  :type="isMobile ? '' : 'flex'"
                  justify="start"
                  align="middle"
                  class="transaction-detail-wrapper"
                >
                  <span class="label">{{ $t("NO_OF_COIN") }}: </span>
                  <br v-if="!isMobile">
                  <i v-if="!isMobile" class="custom-icon icon-lock mini green-icon" />
                  <span class="text-green text-bold">
                    {{ el.coin }}
                  </span>
                  <span class="label">{{ $t("AMOUNT_TO_DEPOSIT") }}: </span>
                  <br v-if="!isMobile">
                  <b>{{ el.post.currency.code.toUpperCase() }} {{ el.post.price*el.coin }}</b>
                </el-row>
              </el-col>

              <el-col :span="isMobile ? 24 : 8" >
                <component :is="isMobile ? 'div' : 'el-row'"
                  type="flex"
                  align="middle"
                >
                  <el-row 
                    type="flex" 
                    justify="start" 
                    align="middle" 
                    class="timer" 
                    :class="{ 'text-red': el._lessThan1Min }"
                  >
                    <i class="custom-icon icon-clock mini"/>
                    {{ el._remainingTime }}
                    <el-popover
                      placement="top-start"
                      :trigger="isMobile ? 'click' : 'hover'"
                      :value="visibleTooltips[el.id]"
                    >
                      <i slot="reference" @click.stop="showTooltipForMobile(el)" class="custom-icon icon-info mini" />
                      <span v-html="getDesc(el)" />
                    </el-popover>
                  </el-row>
                  <template v-if="canAction(el)">
                    <el-button class="button red link-style" @click.stop="handleAction(el, false)">
                      {{ (isPendingDeposit(el) && isSellPost(el)) ? $t("CANCEL") : $t("REJECT") }}
                    </el-button>
                    <el-button class="button green" @click.stop="handleAction(el, true)">
                      {{ isPendingDeposit(el) ? $t("CONFIRM_DEPOSIT") : $t("DEPOSIT_RECEIVED") }}
                    </el-button>
                  </template>
                  <template v-else>
                    <el-button class="button black reversed-color text-grey" disabled>
                      {{ $t("PLEASE_WAIT") }}
                    </el-button>
                  </template>
                </component>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-divider/>

        <el-row
          :type="isMobile ? '' : 'flex'"
          class="w-100"
          :justify="isPendingDeposit(el) && canAction(el) ? 'space-between' : 'end'"
          align="middle"
        >
          <el-col v-if="isPendingDeposit(el) && canAction(el)" :span="isMobile ? 24 : 8">
            <el-steps :space="40" direction="vertical">
              <el-step>
                <el-row
                  slot="title"
                  type="flex"
                  align="middle"
                >
                  <i v-if="!isMobile" class="custom-icon icon-deposit-money mini"/>
                  <b>{{ $t("DEPOSIT_MONEY") }}</b>
                </el-row>
              </el-step>
              <el-step>
                <el-row
                  slot="title"
                  type="flex"
                  align="middle"
                >
                  <i v-if="!isMobile" class="custom-icon icon-confirm-deposit mini"/>
                  <span v-html="$t('CLICK_ACTION', { action: $t('CONFIRM_DEPOSIT') })" />
                </el-row>
              </el-step>
            </el-steps>
          </el-col>

          <el-col :span="isMobile ? 24 : 16">
            <bank-details 
              :showIcon="!isMobile"
              :detail="el"
              :displayFor="isSeller(el) ? 'buyer' : 'seller'"
              :refId="el.transactionRef"
            />
          </el-col>
        </el-row>

      </div>
    </div>

    <pagination
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      :pageSizes="pageSizes"
      @pagination="handleCurrentChange"
    />
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import BankDetails from '../components/bank-details.vue';
import TableActionRow from "@/components/table/table-action-row.vue";
import Pagination from "@/components/table/pagination.vue";
import { generalMixin } from "@/utils/general-mixin.js";
import { PAGE_SIZES } from "@/constants";
import { getTransactionList, updateTransaction } from "@/api/p2p-market";
import { mapGetters, mapState } from "vuex";
import { getRemainingTime, escapeHtml } from "@/utils/helpers.js";

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  orderCol: "COALESCE(transferred_date, initiated_date)",
  desc: false,
  toReleaseListing: true,
  toMakePaymentListing: true,
};

export default {
  name: "P2PMarketTradeList",
  mixins: [generalMixin],
  components: { TableActionRow, Pagination, BankDetails },
  data() {
    return {
      moment: moment,
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      total: 0,
      list: [],
      showMoreInfoIdList: [],
      timeRemainInterval: null,
      getListInterval: null,
      visibleTooltips: {},
      pageSizes: cloneDeep(PAGE_SIZES),
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapState('p2p-market', ['latestTimeout']),
    searchKeyword: {
      get() {
        return this.listQuery.search;
      },
      set(value) {
        this.listQuery.search = value;
      },
    },
  },
  watch: {
    latestTimeout(value) {
      if (value && this.list.length === 0) this.getList() // refresh and get the new transaction 
    }
  },
  methods: {
    showTooltipForMobile(el) {
      let value = this.visibleTooltips[el.id]
      if (value !== null && value !== undefined) value = !value
      this.$set(this.visibleTooltips, el.id, value)
    },
    handleReset() {
      this.listQuery = cloneDeep(filterQuery);
      this.handleFilter();
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page;
      this.listQuery.limit = val.limit;
      this.getList();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch == false) {
        this.searchKeyword = "";
        this.getList();
      }
    },
    isPendingDeposit(el) {
      return !el.transferredDate 
          && el.initiatedDate 
          && el.transactionStatus === 'INITIATED'
    },
    isSellPost(el) {
      return el.post.type === "SELL"
    },
    isUserSelling(el) {
      return el.seller?.id === this.user.id
    },
    isSeller(el) {
      return el.seller.id === this.user.id
    },
    canAction(el) {
      return this.isPendingDeposit(el) && el.buyer?.id === this.user.id
          || !this.isPendingDeposit(el) && el.seller?.id === this.user.id
    },
    handleRowClick(row) {
      this.goTo(`/bo/p2p-market/transaction/${row.transactionRef}`)
    },
    getHeader(el) {
      let result = ""
      let isSeller = el.seller.id === this.user.id
      let name = escapeHtml(this.getFullName(el.seller))

      if (isSeller) {
        if (this.isPendingDeposit(el)) {
          name = escapeHtml(this.getFullName(el.buyer))
        } else {
          name = this.$t("YOU")
        }
      }
      
      if (this.isPendingDeposit(el)) {
        result += this.$t("NEW_TRADE_WITH", { name })
      } else {
        result += this.$t("WAITING_FOR_TO_RELEASE", { name })
      }
                  
      return result
    },
    getDesc(el) {
      let result = ""
      if (this.canAction(el)) {
        if (this.isPendingDeposit(el)) {
          result = this.$t('NEW_BUY_TRADE_DESC')
        } else {
          result = this.$t('NEW_SELL_TRADE_DESC')
        }
      } else {
        if (this.isPendingDeposit(el)) {
          result = this.$t('PENDING_BUY_TRADE_DESC')
        } else {
          result = this.$t('PENDING_SELL_TRADE_DESC')
        }
      }
      return result
    },
    getList() {
      this.isLoading = true;
      getTransactionList(this.listQuery)
        .then((res) => {
          if (res.status != "ok") return;
          console.log("Got: ", res);
          this.list = cloneDeep(res.result.list)
          this.total = res.result.total;
          
          this.loopUpdateList() // initial call
          if (this.timeRemainInterval != null) {
            clearInterval(this.timeRemainInterval)
          }
          this.timeRemainInterval = setInterval(() => {
            this.loopUpdateList()
          }, 1000)
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
    toggleDetail(id) {
      const found = this.showMoreInfoIdList.findIndex(i => i === id) !== -1
      if (found) {
        this.showMoreInfoIdList = this.showMoreInfoIdList.filter(i => i !== id)
      } else {
        this.showMoreInfoIdList.push(id)
      }
    },
    showDetail(id) {
      return this.showMoreInfoIdList.find(i => i === id)
    },
    loopUpdateList() {
      this.list = this.list.map(el => {
        let toCompareTime = el.transferredDate
        if (this.isPendingDeposit(el)) {
          toCompareTime = el.initiatedDate
        }
        const timeObj = getRemainingTime(toCompareTime, 15*60)

        if (timeObj.remainingInSec <= 0) { 
          el._remainingTime = "00:00"
          el._lessThan1Min = true
        } else {
          el._remainingTime = timeObj.formatted
          el._lessThan1Min = timeObj.isLessThan1Min
        }

        return el
      })
    },
    handleAction(el, isApprove) {
      let confirmMsgCode = "CONFIRMATION_DEPOSIT_RECEIVED"
      if (this.isPendingDeposit(el)) confirmMsgCode = "CONFIRMATION_DEPOSIT_MADE"
      if (!isApprove) {
        if (this.isPendingDeposit(el) && this.isSellPost(el)) {
          confirmMsgCode = "CONFIRMATION_CANCEL"
        } else {
          confirmMsgCode = "CONFIRMATION_REJECT"
        }
      }
      const amount = `<b>${el.post.currency.code.toUpperCase()} ${el.post.price*el.coin}</b>`

      this.$confirm(
        this.$t(confirmMsgCode, { amount }), 
        this.$t("ARE_YOU_SURE"), 
        { dangerouslyUseHTMLString: true, customClass: "trade-list-popup" }
      )
      .then(() => {
        this.isLoading = true
        let postData = { transactionRef: el.transactionRef, released: isApprove }
        if (this.isPendingDeposit(el)) postData = { transactionRef: el.transactionRef, transferred: isApprove }

        updateTransaction(postData)
        .finally(() => {
          this.isLoading = false
          this.getList()
        })
        
      })
      .catch(() => {});
    }
  },
  mounted() {
    this.handleReset()

    if (this.isMobile) {
      this.pageSizes.unshift(5)
      filterQuery.limit = 5
      this.listQuery.limit = 5
    }

    if (this.getListInterval != null) {
      clearInterval(this.getListInterval)
      this.getListInterval = null
    }
    this.getListInterval = setInterval(() => {
      this.getList()
    }, 5000)

    this.$store.dispatch("p2p-market/cancelLoopGetEarliestTransactionTimestamp", false)
  },
  beforeDestroy() {
    clearInterval(this.timeRemainInterval)
    this.timeRemainInterval = null
    clearInterval(this.getListInterval)
    this.getListInterval = null

    this.$store.dispatch("p2p-market/loopGetEarliestTransactionTimestamp")
  }
};
</script>
  
<style lang="scss">
@import "@/assets/style/mixin/table.scss";
@import "@/assets/style/_variables.scss";

#p2p-market-trade-list {
  @include newsily-bo-table;
  .list-wrapper {
    .trade-container {
      margin-top: 1rem;
      padding: 26px;
      padding-bottom: 10px;
      background-color: $white;
      border-radius: 10px;
      border: 1px solid transparent; // later define by primary/green

      &.empty {
        color: #9893B7;
        padding-bottom: 26px;
      }

      &.primary {
        border-color: $primary
      }

      &.green {
        border-color: #52BB58;
      }

      &:hover {
        box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 18%);
      }

      .header-tag {
        // @include truncateForRow(2);
        border-radius: 20px;
        background-color: transparent;
        &.primary {
          border: 2px dotted $primary;
          color: $primary;
        }
        &.green {
          border: 2px dotted #52BB58;
          color: #52BB58;
        }
        font-size: 0.8rem;
        height: 100%;
        white-space: initial;
      }

      .transaction-detail-wrapper {
        .label {
          margin: auto 1rem;
          font-weight: bold;
          margin-bottom: 0;
          font-size: 0.8rem;
          color: #253858;
          line-height: 36px;
        }
      }

      .icon-deposit-money,
      .icon-confirm-deposit {
        margin-right: 5px;
      }

      .timer {
        margin-right: 1rem;
        font-weight: bold;
        color: #FFB127;
        font-size: 1.5rem;
        &.text-red {
          .icon-clock {
            @extend .red-icon;
          }
        }
        .icon-info {
          margin-left: 5px;
        }
      }
      .el-step {
        * {
          color: $black;
        }
        .el-step__icon {
          background-color: $grey;
          border: none;
        }
      }
      .button {
        width: 10rem;
      }
      .el-divider {
        margin: 0.75rem 0;
      }
    }
  }
}

.trade-list-popup {
  .el-message-box__header {
    border-bottom: 1px solid #c7c7c77d;
  }
  .el-message-box__btns {
    border-top: 1px solid #c7c7c77d;
    text-align: center;
    .el-button--primary {
      background: #7DC12F;
      color: #ffffff;
      width: auto !important;
      font-weight: bold;
      border: none;
    }
  }
  .el-message-box__content {
    padding: 3rem 1rem;
    word-break: break-word;
    color: #767396;
  }
}

.mobile #p2p-market-trade-list {
  .transaction-detail-wrapper {
    margin: 5px 0;
    * {
      display: inline-block;
    }
    .label {
      width: 50%;
    }
  }

  .list-wrapper .trade-container .transaction-detail-wrapper .label {
    margin: 0;
  }

  .header-wrapper {
    position: relative;
    .timer {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .list-wrapper .trade-container .button {
    margin: 5px;
    width: fit-content;
    display: inline-block;
  }

  .header-tag {
    max-width: 50%;
  }
}
</style>
  