<template>
  <div id="p2p-market-your-request-list">
    
    <h1>{{ $t("BUY_REQUEST") }}</h1>
    <your-buy-sell-table type="BUY" />

    <el-divider />

    <h1>{{ $t("SELL_REQUEST") }}</h1>
    <your-buy-sell-table type="SELL" />
    
  </div>
</template>

<script>
import YourBuySellTable from "./components/your-buy-sell-table.vue"

export default {
  name: "P2PMarketYourRequestList",
  components: { YourBuySellTable },
  data() {
    return { };
  },
};
</script>

<style lang="scss">
#p2p-market-your-request-list {
  h1 {
    margin-bottom: 2rem;
  }
}
</style>
  