<template>
  <div id="p2p-market-buy-list">
    
    <table-action-row
      :filterList="listQuery"
      @onFilterChange="
        (value) => {
          listQuery = value;
          handleFilter();
        }
      "
      :handleAction="() => { handleCreate()}"
      actionButtonCode="BUY_REQUEST"
      actionButtonIcon="icon-add"
    />

    <el-table 
      v-loading="isLoading" class="newsily-bo-table" :data="list"
    >
      <el-table-column :label="$t('NO_OF_COIN')">
        <template slot-scope="{row}">
          {{ row.availableCoins }}
          <br v-if="isMobile">
          <span v-if="row.reservedCoins && row.reservedCoins !== 0" class="reserved-coin">
            <i class="custom-icon icon-lock mini"/>
            {{ row.reservedCoins }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('PRICE_PER_COIN')">
        <template slot-scope="{row}">
          {{ `${row.currency.code.toUpperCase()} ${row.price}` }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('NAME')">
        <template slot-scope="{row}">
          <span :class="row.user.id === user.id ? 'text-green' : ''">
            {{ getFullName(row.user) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('ACTION')"
        width="150"
        class-name="small-padding"
        prop="actions"
      >
        <template slot-scope="{ row }">
          <el-button
            v-if="row.availableCoins > 0 || row.user.id === user.id"
            class="button"
            :class="row.user.id === user.id ? 'green link-style' : 'black'"
            @click="goTo('/bo/p2p-market/buy-detail/'+row.id)"
          >
            {{ row.user.id === user.id ? $t("EDIT") : $t("BUY") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

    <delete-post-dialog v-model="dialogVisible" type="BUY" :url="myPostURL"  />
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import TableActionRow from "@/components/table/table-action-row.vue";
import Pagination from "@/components/table/pagination.vue";
import DeletePostDialog from "../components/delete-post-dialog.vue";
import { generalMixin } from "@/utils/general-mixin.js";
import { PAGE_SIZES } from "@/constants";
import { getPostList, getActivePostByLogin } from "@/api/p2p-market";
import { mapGetters } from "vuex";

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  p2pPost: {
    currency: {
      id: null
    },
    type: "SELL",
  },
};

export default {
  name: "P2PMarketBuyList",
  mixins: [generalMixin],
  components: { TableActionRow, Pagination, DeletePostDialog },
  data() {
    return {
      moment: moment,
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      total: 0,
      list: [],
      dialogVisible: false,
      myPostURL: ''
    };
  },
  computed: {
    ...mapGetters(["user", "currencyList"]),
    searchKeyword: {
      get() {
        return this.listQuery.search;
      },
      set(value) {
        this.listQuery.search = value;
      },
    },
  },
  methods: {
    handleReset() {
      this.listQuery = cloneDeep(filterQuery);
      this.handleFilter();
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page;
      this.listQuery.limit = val.limit;
      this.getList();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch == false) {
        this.searchKeyword = "";
        this.getList();
      }
    },
    getList() {
      this.isLoading = true;
      // hardcode first, next time if got multiple currency, chg me
      this.listQuery.p2pPost.currency = this.currencyList.find(el => el.code === "myr")
      getPostList(this.listQuery)
        .then((res) => {
          if (res.status != "ok") return;
          console.log("Got: ", res);
          this.list = cloneDeep(res.result.list);
          this.total = res.result.total;
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
    handleCreate() {
      let type = "BUY"
      getActivePostByLogin({type})
        .then((res) => {
          if (res.status != "ok") return;
          const isFound = res.result;

          if(isFound && Object.keys(isFound).length === 0) this.goTo('/bo/p2p-market/buy-detail') 
          else {
            this.dialogVisible = true
            this.myPostURL = `/bo/p2p-market/sell-detail/${isFound.id}`
          }
        })
        .catch(this.handleError)
        .finally();
    }
  },
  async mounted() {
    this.handleReset();
  },
};
</script>
  
<style lang="scss">
@import "@/assets/style/mixin/table.scss";

#p2p-market-buy-list {
  @include newsily-bo-table;
  .newsily-bo-table {
    .button {
      margin: auto;
      padding: 0 30px;
      height: 1.5rem;
    }
    .reserved-coin {
      padding: 2px 10px;
      background: #FFB127 0% 0% no-repeat padding-box;
      border-radius: 18px;
      color: $white;
      font-weight: bold;
      .custom-icon {
        height: 13px;
        width: 13px;
        background-size: 13px 13px;
      }
    }
  }
}
</style>
  