<template>
  <div id="p2p-market-listing" :class="{'mobile': isMobile}">
    <el-tabs v-model="activeTab" @tab-click="selectTab()" :stretch="isMobile">
      <el-tab-pane :label="$t('BUY')" name="buy" />
      <el-tab-pane :label="$t('SELL')" name="sell" />
      <el-tab-pane :label="$t('YOUR_REQUEST')" name="your-request" />
      <el-tab-pane :label="$t('TRADE')" name="trade" />
      <el-tab-pane :label="$t('HISTORY')" name="history" />
    </el-tabs>

    <transition appear name="slide-from-right">
      <div :key="$route.fullPath" class="content">
        <buy-list v-if="activeTab === 'buy'" />
        <sell-list v-if="activeTab === 'sell'" />
        <your-request-list v-if="activeTab === 'your-request'" />
        <trade-list v-if="activeTab === 'trade'" />
        <history-list v-if="activeTab === 'history'" />
      </div>
    </transition>
  </div>
</template>

<script>
import BuyList from "./buy/buy-list"
import SellList from "./sell/sell-list"
import YourRequestList from "./post/your-request-list"
import TradeList from "./trade/trade-list"
import HistoryList from "./history/history-list"
import { generalMixin } from "@/utils/general-mixin.js"

export default {
  name: 'P2PMarketPage',
  components: { BuyList, SellList, YourRequestList, TradeList, HistoryList },
  mixins: [generalMixin],
  data() {
    return {
      activeTab: "",
    }
  },
  computed: {
    currentTabName() {
      switch(this.activeTab) {
        case 'buy': return this.$t("BUY")
        case 'sell': return this.$t("SELL")
        case 'your-request': return this.$t("YOUR_REQUEST")
        case 'trade': return this.$t("TRADE")
        default: return this.$t("HISTORY")
      }
    }
  },
  methods: {
    selectTab() {
      let chosenTab = this.activeTab
      this.$router.replace({ ...this.$router.currentRoute, query: {chosenTab} }).catch(() => {})
    }
  },
  mounted() {
    this.activeTab = this.$route.query?.chosenTab || 'buy';
  },
  watch: {
    '$route.query.chosenTab'(tab) {
      this.activeTab = tab || 'buy'
    }
  }
}
</script>
  
<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/tabs.scss";

#p2p-market-listing {
  @include mobile-tabs;

  position: relative;
  .el-tabs__header {
    position: absolute;
    top: -4rem;
    left: 25rem;

    .el-tabs__item {
      font-size: 15px;
      line-height: 32px;
    }
  }
}
</style>