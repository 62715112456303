<template>
  <div id="p2p-market-your-sell-table">
    <table-action-row
      :filterList="listQuery"
      @onFilterChange="
        (value) => {
          listQuery = value;
          handleFilter();
        }
      "
    />

    <el-table 
      v-loading="isLoading" class="newsily-bo-table" :data="list"
    >
      <el-table-column :label="$t('TIME')" :width="isMobile ? '150' : null">
        <template slot-scope="{row}">
          {{ parseDate(row.createdDate) }}
        </template>
      </el-table-column>

      <el-table-column prop="initialCoin" :label="$t('MAX_NUM_OF_COIN')" />

      <el-table-column prop="availableCoins" :label="$t('AVAILABLE_COIN')" />

      <el-table-column :label="$t('RESERVED_COIN')">
        <template slot-scope="{row}">
          <span class="reserved-coin">
            <i class="custom-icon icon-lock mini"/>
            {{ row.reservedCoins ? row.reservedCoins : 0 }}
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('PRICE_PER_COIN')">
        <template slot-scope="{row}">
          {{ `${row.currency.code.toUpperCase()} ${row.price}` }}
        </template>
      </el-table-column>

      <el-table-column prop="status" :label="$t('STATUS')" width="100">
        <template slot-scope="{ row }">
          <div>
            <span :class="getStatusClass(row.status)">{{
              $t(row.status)
            }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('ACTION')"
        width="150"
        class-name="small-padding"
        prop="actions"
      >
        <template slot-scope="{ row }">
          <el-button
            v-if="row.status != 'CLOSED'"
            @click="goToDetail(row)"
            class="button green link-style"
          >
            {{ $t("EDIT") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      :pageSizes="pageSizes"
      @pagination="handleCurrentChange"
    />
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import TableActionRow from "@/components/table/table-action-row.vue";
import Pagination from "@/components/table/pagination.vue";
import { generalMixin } from "@/utils/general-mixin.js";
import { PAGE_SIZES } from "@/constants";
import { getPostList } from "@/api/p2p-market";
import { mapGetters } from "vuex";

const filterQuery = {
  page: 1,
  search: "",
  limit: 5,
  orderCol: "created_date",
  desc: true,
  p2pPost: {
    currency: {
      id: null
    },
    type: "",
  },
  showSelfListingOnly: true
};

export default {
  name: "P2PMarketSellList",
  mixins: [generalMixin],
  components: { TableActionRow, Pagination },
  props: {
    type: { type: String, required: true }
  },
  data() {
    return {
      moment: moment,
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      pageSizes: [5, ...cloneDeep(PAGE_SIZES)],
      total: 0,
      list: [],
    };
  },
  computed: {
    ...mapGetters(["user", "currencyList"]),
    searchKeyword: {
      get() {
        return this.listQuery.search;
      },
      set(value) {
        this.listQuery.search = value;
      },
    },
  },
  methods: {
    handleReset() {
      this.listQuery = cloneDeep(filterQuery);
      this.handleFilter();
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page;
      this.listQuery.limit = val.limit;
      this.getList();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch == false) {
        this.searchKeyword = "";
        this.getList();
      }
    },
    goToDetail(row) {
      let detailName = "buy-detail"
      if (row.type === "SELL") {
        detailName = "sell-detail"
      }
      this.goTo(`/bo/p2p-market/${detailName}/${row.id}`)
    },
    getList() {
      this.isLoading = true;
      // hardcode first, next time if got multiple currency, chg me
      this.listQuery.p2pPost.currency = this.currencyList.find(el => el.code === "myr")
      this.listQuery.p2pPost.type = this.type
      getPostList(this.listQuery)
        .then((res) => {
          if (res.status != "ok") return;
          // console.log("Got: ", res);
          this.list = cloneDeep(res.result.list);
          this.total = res.result.total;
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
  },
  async mounted() {
    this.handleReset();
  },
};
</script>
  
<style lang="scss">
@import "@/assets/style/mixin/table.scss";

#p2p-market-your-sell-table {
  position: relative;
  @include newsily-bo-table;
  .newsily-bo-table {
    .button {
      margin: auto;
      padding: 0 30px;
      height: 1.5rem;
    }
    .reserved-coin {
      padding: 2px 10px;
      background: #FFB127 0% 0% no-repeat padding-box;
      border-radius: 18px;
      color: $white;
      font-weight: bold;
      .custom-icon {
        height: 13px;
        width: 13px;
        background-size: 13px 13px;
      }
    }
  }
}
</style>