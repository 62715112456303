<template>
  <div class="bank-details" v-if="detailToTake">
    <el-tag 
      v-if="label"
      class="buyer-details-tag"
      :style="labelStyle"
    >
      {{ label }}
    </el-tag>
    <el-divider v-if="label"/>
    <el-row :type="isMobile ? '':'flex'" justify="space-between" align="middle" :gutter="40">
      <el-col v-if="showIcon" :span="2">
        <i class="custom-icon icon-seller-detail"/>
      </el-col>
      <el-col>
        <div class="label">{{ $t("BANK_NAME") }}:</div>
        <span>{{ detailToTake.bankName.name }}</span>
      </el-col>
      <el-col>
        <div class="label">{{ $t("BANK_ACC_HOLDER_NAME") }}:</div>
        <span>{{ decrypt(detailToTake.bankAccountHolderName) }}</span>
      </el-col>
      <el-col>
        <div class="label">{{ $t("BANK_ACC_NUM") }}:</div>
        <span>{{ decrypt(detailToTake.bankAccountNum) }}</span>
        <i
          class="custom-icon mini pointer icon-copy"
          @click.stop="copy(decrypt(detailToTake.bankAccountNum))"
        />
      </el-col>
      <el-col v-if="!fullyExtend">
        <template v-if="refId">
          <div class="label">{{ $t("TRANSACTION_REFERENCE_ID") }}:</div>
          <span>{{ refId }}</span>
          <i
            class="custom-icon mini pointer icon-copy"
            @click.stop="copy(refId)"
          />
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { fallbackCopyTextToClipboard } from "@/utils/helpers.js";
import { decrypt } from "@/utils/encryption"
import { generalMixin } from "@/utils/general-mixin.js";

export default {
  name: "P2PMarketBankDetail",
  props: {
    // 1. ONLY one of these
    userObj: { type: Object },

    // 2. ONLY one of these
    detail: { type: Object },
    displayFor: { type: String }, // buyer/seller

    label: { type: String },
    refId: { type: String },
    labelFillColor: { type: String },
    labelTextColor: { type: String },
    labelBorderColor: { type: String },
    showIcon: { type: Boolean },

    fullyExtend: { type: Boolean, default: false },
  },
  mixins: [generalMixin],
  data() {
    return {
      decrypt: decrypt,
    }
  },
  computed: {
    labelStyle() {
      let result = ""
      if (this.labelFillColor) result += `background-color: ${this.labelFillColor}; `
      if (this.labelTextColor) result += `color: ${this.labelTextColor}; `
      if (this.labelBorderColor) result += `border: 1px solid ${this.labelBorderColor}; `
      
      return result
    },
    detailToTake() {
      if (this.userObj) {
        return this.userObj
      } else if (this.detail) {
        const capitalizeFirstLetter = (string) => {
          return string.charAt(0).toUpperCase() + string.slice(1);
        }
        let result = {}
        const keyNames = ["bankAccountNum", "bankAccountHolderName", "bankName"]
        keyNames.forEach(key => {
          result[key] = this.detail[this.displayFor+capitalizeFirstLetter(key)]
        })
        return result
      }
      console.warn("detail is empty !!! (might be the data havent loaded properly, just warning)")
      return null
    }
  },
  methods: {
    copy(value) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(value);
      } else {
        navigator.clipboard.writeText(value);
      }
      this.$notify({
        message: this.$t("COPY_TO_CLIPBOARD"),
        duration: this.NOTIFY_DURATION,
        position: this.NOTIFY_POSITION
      });
    },
  }
  
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

.bank-details {
  .buyer-details-tag {
    border-radius: 20px;
    background-color: $white;
    color: #767396;
    border: 1px solid #767396;
    font-size: 16px;
    font-weight: bold;
  }
  .label {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 0.8rem;
    color: #253858;
    line-height: 36px;
  }
  .icon-copy {
    float: right;
  }
  span {
    color: #767396;
    font-size: 0.9rem;
  }
}
.mobile {
  .bank-details {
    h3, .el-tag {
      font-size: 14px;
    }
    .label {
      margin-bottom: 0!important;
      line-height: inherit;
    }
    .el-col {
      margin-bottom: 0.5rem;
    }
  }
}
</style>